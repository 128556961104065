/* ! жоско зафиксил маскимальную ширину экрана */

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans", sans-serif;

  background: #f5f6f8;
  /* max-width: 2048px; */
  min-width: 320px;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* height: 5000px; */
}

main {
  flex-grow: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
li {
  list-style: none;
}
h2,
h1 {
  font-family: "Rubik Mono One", sans-serif;
}
button {
  border: 0;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
